import { Trans } from '@lingui/macro';

import { PixelType } from '@/api/pixel';
import { Flex } from '@/components/Flex';
import { Tag } from '@/components/typography';
import { getChannelColor } from '@/constants/colors/channel';

type Props = {
  pixels?: PixelType[];
};

const PixelTagList = ({ pixels }: Props) => {
  return (
    <Flex justify="end" gap="small" wrap>
      {pixels?.map((pixel) => {
        const channelColor = getChannelColor(pixel.channel, 0.5);

        return (
          <Tag key={pixel.id} size="medium" variant="solid" color={channelColor} noMargin>
            <Trans>
              {pixel.name} - {pixel.vendor.name} - {pixel.channel.name}
            </Trans>
          </Tag>
        );
      })}
    </Flex>
  );
};

export default PixelTagList;
