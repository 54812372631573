import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Text } from '@/components/typography';

import styles from './TooltipRow.module.scss';

type Props = {
  name: string;
  value?: string | number | null;
  valueLink?: string | null | false;
  secondaryValue?: string | number | null | false;
  color?: string;
  valueOverflow?: boolean;

  // An unfortunate hack to prevent tooltips from using `Link` components when they're rendered
  // using `renderToStaticMarkup` for Highcharts.
  unstable_hasRouterContext?: boolean;
};

const TooltipRow = ({
  name,
  value,
  valueLink,
  secondaryValue,
  color,
  valueOverflow = false,
  unstable_hasRouterContext = true,
}: Props) => {
  if (value == null) {
    return null;
  }

  const multiLineName = `${name}`.split('<br/>');
  const multiLineValue = `${value}`.split('<br/>');
  const multiLineSecondaryValue = secondaryValue && `${secondaryValue}`.split('<br/>');

  return (
    <tr className={styles.tooltipRow}>
      <td className={styles.labelCell}>
        <div className={styles.labelContainer}>
          {color != null && (
            <span
              className={styles.tooltipCircle}
              style={{
                backgroundColor: color,
              }}
            />
          )}
          <div className={styles.nameContainer}>
            {multiLineName.map((name) => (
              <Text
                key={name}
                className={styles.name}
                variant="body2"
                color="white"
                weight="regular"
              >
                {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
                {name}:
              </Text>
            ))}
          </div>
        </div>
      </td>
      <td className={styles.secondaryValueCell}>
        {multiLineSecondaryValue &&
          multiLineSecondaryValue.map((val) => (
            <Text key={val} variant="body2" className={styles.value} color="white">
              {val}
            </Text>
          ))}
      </td>
      <td className={styles.valueCell}>
        <div
          className={classNames(styles.valueContainer, { [styles.scrollValues]: valueOverflow })}
        >
          {multiLineValue.map((val) => {
            const isLinked = !!valueLink;

            const content = (
              <Text
                key={val}
                id={!unstable_hasRouterContext && valueLink ? valueLink : undefined}
                variant="body2"
                className={styles.value}
                color="white"
                underline={isLinked}
                pointer={isLinked}
              >
                {val}
              </Text>
            );

            if (isLinked && unstable_hasRouterContext) {
              return (
                <Link to={valueLink} key={val}>
                  {content}
                </Link>
              );
            }
            return content;
          })}
        </div>
      </td>
    </tr>
  );
};

export default TooltipRow;
