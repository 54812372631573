import { ConfigProvider } from 'antd';
import { PropsWithChildren } from 'react';

import {
  COLOR_AQUA_400,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_RED_400,
  COLOR_YELLOW_400,
} from '@/styles/palette';

const AntDConfigProvider = (props: PropsWithChildren) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLOR_GREEN_500,
          fontFamily: `'Inter', sans-serif`,
          colorSuccess: COLOR_GREEN_400,
          colorError: COLOR_RED_400,
          colorWarning: COLOR_YELLOW_400,
          colorInfo: COLOR_AQUA_400,
          colorLink: COLOR_AQUA_400,
        },
      }}
      {...props}
    />
  );
};

export default AntDConfigProvider;
