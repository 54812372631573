import { Trans, t } from '@lingui/macro';
import { Col, Row } from 'antd';

import { InstanceDetailDataType } from '@/api/organization';
import Form from '@/components/Form';
import { CopyToClipboardTextArea } from '@/components/Form';
import { Panel } from '@/components/panels';
import { Text } from '@/components/typography';

import styles from './CompanySettingsTag.module.scss';

type Props = {
  instance: InstanceDetailDataType;
};

const CompanySettingsTag = ({ instance }: Props) => {
  return (
    <Panel title={t`Channel99 Tag`}>
      <Row gutter={[40, 24]}>
        <Col xxl={8} xl={9} lg={10} md={24} sm={24}>
          <Form.Item label={<Trans>Instructions</Trans>}>
            <Text variant="body1">
              <Trans>To install the C99 tag:</Trans>
            </Text>
          </Form.Item>
          <ol className={styles.installSteps} type="1">
            <li>
              <Text variant="body1">
                <Trans>Log in to your Google Tag Manager account</Trans>
              </Text>
            </li>
            <li>
              <Text variant="body1">
                <Trans>Go to Tags -&gt; Tag Configuration -&gt; Custom HTML</Trans>
              </Text>
            </li>
            <li>
              <Text variant="body1">
                <Trans>Copy the following script with your C99 Token</Trans>
              </Text>
            </li>
            <li>
              <Text variant="body1">
                <Trans>Set tag to "Triggering" and choose "All Pages"</Trans>
              </Text>
            </li>
            <li>
              <Text variant="body1">
                <Trans>Click Submit (upper right)</Trans>
              </Text>
            </li>
          </ol>
        </Col>
        <Col xl={14} lg={14} md={24} sm={24} xs={24}>
          <Form.Item label={<Trans>Channel99 Tag (Copy and Place)</Trans>}>
            <CopyToClipboardTextArea
              value={`<script async id="tagserver" src="${import.meta.env.VITE_TAG_BASE_URL}/api/v1/fire/snippet?domain=${instance.domain}&token=${instance.code}&referrer={{Referrer}}"></script>`}
            />
          </Form.Item>
        </Col>
      </Row>
    </Panel>
  );
};

export default CompanySettingsTag;
