import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import moment from 'moment';

import { EventLogInputType } from '@/api/eventLog';
import Form, { DatePicker, TextArea } from '@/components/Form';
import { Button } from '@/components/buttons';
import { DateBEFormat } from '@/constants/formats';

import styles from './NotesPanelDrawerAddSection.module.scss';

enum FORM_FIELD {
  DATE = 'date',
  DETAILS = 'details',
}

type FormType = {
  [FORM_FIELD.DATE]: moment.Moment;
  [FORM_FIELD.DETAILS]: string;
};

type Props = {
  defaultNote?: EventLogInputType;
  isSaving?: boolean;
  onSave: (note: EventLogInputType) => void;
  onCancel: () => void;
};

const NotesPanelDrawerEdit = ({ defaultNote, isSaving, onSave, onCancel }: Props) => {
  const [form] = Form.useForm<FormType>();

  const handleFinish = ({ date, details }: FormType) => {
    if (date && details) {
      onSave({
        id: defaultNote?.id,
        date: `${date.format(DateBEFormat)}T00:00:00Z`,
        details,
        rawDetails: details as string,
      });
    }
  };

  return (
    <Form form={form} layout="horizontal" validateTrigger="onBlur" onFinish={handleFinish}>
      <div className={styles.container}>
        <Form.Item
          className={styles.formItem}
          name={FORM_FIELD.DATE}
          initialValue={defaultNote ? moment(defaultNote.date).utc() : moment().utc().startOf('d')}
        >
          <DatePicker size="small" maxDate={moment().utc().endOf('d')} />
        </Form.Item>
        <Form.Item
          className={classNames(styles.formItem, styles.contentInput)}
          name={FORM_FIELD.DETAILS}
          initialValue={defaultNote?.rawDetails}
          required={false}
          rules={[{ required: true, message: t`Please enter a note` }]}
        >
          <TextArea
            className={styles.textarea}
            placeholder={t`Type your note here`}
            size="small"
            rows={3}
            maxLength={200}
            showCount
          />
        </Form.Item>
        <div className={styles.actions}>
          <Button type="submit" color="green" variant="primary" size="small" isLoading={isSaving}>
            <Trans>Save</Trans>
          </Button>
          <Button
            color="black"
            variant="secondary"
            size="small"
            isDisabled={isSaving}
            onClick={onCancel}
          >
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default NotesPanelDrawerEdit;
