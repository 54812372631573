import { Trans, t } from '@lingui/macro';

import { TrafficKPIType } from '@/api/common';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  trafficKPIData: TrafficKPIType[];
  isLoading?: boolean;
  error?: Error | null;
};

const TrafficKPIs = ({ trafficKPIData, isLoading, error }: Props) => {
  return (
    <KpiMetricGroup>
      {trafficKPIData?.map((kpi) => {
        const captionVal =
          kpi.lastPeriod === null ? (
            <Text variant="caption1" color="grey">{t`Partial Data`}</Text>
          ) : (
            numberFormat(kpi.lastPeriod, kpi.valueFormat)
          );

        return (
          <KpiMetric
            key={kpi.id}
            label={kpi.label}
            value={kpi.value && numberFormat(kpi.value, kpi.valueFormat)}
            valueLink={kpi.valueLink}
            caption={<Trans>Previous Period: {captionVal}</Trans>}
            percentChange={kpi.lastPeriod === null ? null : kpi.percentChange}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default TrafficKPIs;
