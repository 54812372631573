/* eslint-disable lingui/no-unlocalized-strings */
import { useStytchB2BClient } from '@stytch/react/dist/StytchB2BContext-ce9e254c';
import { useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';

type MessageType = {
  type: string;
  token: string;
  content: string;
};

const useAuthWebSocket = (types: string[], onMessageReceived?: (message: string) => void) => {
  const canUseWebsockets = useFeatureFlag(FEATURE_FLAG.websockets);
  const { session } = useStytchB2BClient();
  const [lastMessage, setLastMessage] = useState<MessageEvent | null>(null);

  const jwtToken = session.getTokens()?.session_jwt;
  const socket = useWebSocket<MessageType>(
    canUseWebsockets ? import.meta.env.VITE_WEBSOCKET_URL : null,
    {
      protocols: jwtToken,
      onOpen: () => console.log('socket opened'),
      onClose: () => console.log('socket closed'),
      onError: (event) => console.log('socket error:', event),
      shouldReconnect: () => true,
      onMessage: (event) => {
        // const data = JSON.parse(event.data);
        if (event.data) {
          setLastMessage(event.data);
          onMessageReceived?.(event.data);
        }

        // if (types.includes(data.type)) {
        //   setLastMessage(event);
        //   onMessageReceived?.(event);
        // }
      },
    },
  );

  const handleSendMessage = (message: string) => {
    const payload = {
      type: 'ai:chat',
      token: jwtToken,
      content: message,
    } as MessageType;

    socket?.sendJsonMessage(payload);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[socket?.readyState ?? ReadyState.UNINSTANTIATED];

  return {
    lastMessage,
    onSendMessage: handleSendMessage,
    connectionStatus,
  };
};

export default useAuthWebSocket;
