import { Plural, Trans } from '@lingui/macro';
import { Options, Point, Series, SeriesAreaOptions } from 'highcharts';
import { useEffect, useState } from 'react';

import { FlareChart } from '@/components/Flare';
import { useFlareContext } from '@/components/Flare/FlareContext';
import { Text } from '@/components/typography';

import styles from './ClosedWonTrendSummaryColumn.module.scss';

const MIN_LABEL_HEIGHT = 40;

type AreaSeries = Series[] & SeriesAreaOptions[];

type PositionDataType = {
  height: number;
  label: string;
  value: number;
};

type PointExtended = Point & {
  stackY?: number;
};

const buildOptions = (options: Options, onChartRedraw: (chart: FlareChart) => void): Options => {
  return {
    ...options,
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        redraw: function (event) {
          options.chart?.events?.redraw?.call(this, event);
          onChartRedraw(this as FlareChart);
        },
      },
    },
  };
};

const ClosedWonTrendSummaryColumn = () => {
  const { id, registerChild } = useFlareContext();
  const [positionData, setPositionData] = useState<PositionDataType[] | undefined>();
  const [margin, setMargin] = useState(0);
  const [totalVisits, setTotalVisits] = useState(0);

  const handleRender = (chart: FlareChart) => {
    const series = (chart?.series as AreaSeries) || [];
    const yAxis = chart?.yAxis?.[0];
    const minYPixels = yAxis.toPixels(yAxis?.min ?? 0, true);
    let maxSeriesHeight = 0;
    let totalYVal = 0;
    let adjustedYDiff = 0;

    const posData = [...series]
      // Reverse because we want to start with smallest series for positioning adjustments
      .reverse()
      .filter((s) => s.visible)
      .map((s) => {
        const lastPoint = s.data?.at(-1) as PointExtended;
        const yVal = lastPoint?.y || 0;
        const yStackVal = lastPoint?.stackY || 0;
        let seriesHeight = minYPixels - yAxis.toPixels(yVal, true);

        if (s.visible) {
          maxSeriesHeight = Math.max(maxSeriesHeight, minYPixels - yAxis.toPixels(yStackVal, true));
          totalYVal += yVal;

          if (seriesHeight < MIN_LABEL_HEIGHT) {
            // how much height will we need to give from this smaller segment?
            adjustedYDiff += MIN_LABEL_HEIGHT - seriesHeight;
          } else if (adjustedYDiff > 0) {
            // how much height can we take from this larger segment?
            const available = Math.min(seriesHeight - MIN_LABEL_HEIGHT, adjustedYDiff);
            const seriesYDiff = Math.min(available, adjustedYDiff);
            adjustedYDiff -= seriesYDiff;
            seriesHeight -= seriesYDiff;
          }
        }

        return {
          height: seriesHeight,
          label: s.name,
          value: yVal,
        };
      });

    setPositionData(posData.reverse());

    setMargin(chart.plotHeight - maxSeriesHeight);
    setTotalVisits(totalYVal);
  };

  useEffect(() => {
    registerChild(id, (options: Options) => buildOptions(options, handleRender));
  }, []);

  return (
    <div className={styles.column}>
      {totalVisits > 0 && (
        <>
          <Text variant="caption1" weight="semi-bold">
            <Trans>
              Total visits: <br />
              {totalVisits}
            </Trans>
          </Text>
          <div className={styles.columnValues} style={{ marginTop: margin }}>
            {positionData?.map((item) => (
              <div
                key={item.label}
                className={styles.valueItem}
                style={{
                  height: Math.max(Math.round(item.height), 40),
                }}
              >
                <Text variant="caption1" color="grey">
                  <Trans>{item.label}:</Trans>
                </Text>
                <Text variant="caption1">
                  <Plural value={item.value} one="# Visit" other="# Visits" />
                </Text>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ClosedWonTrendSummaryColumn;
